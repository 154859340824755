import { useParams } from "react-router-dom";
import { useGetOne, useRedirect, Loading } from "react-admin";
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { SimpleShowLayout, TextField } from "react-admin";
import { StyledShow } from "../../themes/ocoach-default/StyledComponents";
import { json } from "stream/consumers";

export const ChallengeInvitationShow: React.FC = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    "challengeInvitationLog",
    { id },

    { onError: () => redirect("/error") }
  );
  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledShow title={`Challenge Invitations ${data.name ? `\\ ${data.name}` : "\\ Challenge Invitation Log"}`}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <TextField source="id" label="Challenge Id" />
        <TextField source="challengeName" label="Challenge Title" style={{ fontWeight: "bold" }} />

        <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
          <Typography variant="h6" style={{ margin: "1rem" }}>
            Challenge Invitation Viewers (Users who accessed the link in the O'Coach app)
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Member ID/ Guest ID</TableCell>
                <TableCell>Member Name</TableCell>
                <TableCell>Member Location</TableCell>
                <TableCell>Device Type</TableCell>
                <TableCell>Device Modal</TableCell>
                <TableCell>Has Joined</TableCell>
                <TableCell>Viewed Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.invitationViewers?.map((viewer: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{viewer.memberId ? viewer.memberId : viewer.guestId}</TableCell>
                  <TableCell>{viewer.memberName ? viewer.memberName : "GUEST USER"}</TableCell>
                  <TableCell>{viewer.location ? viewer.location : "Somewhere from Earth"}</TableCell>
                  <TableCell>{viewer.deviceType ? viewer.deviceType : "NA"}</TableCell>
                  <TableCell>{viewer.deviceModal ? viewer.deviceModal : "NA"}</TableCell>
                  <TableCell style={{ color: viewer.hasJoined ? "green" : "red", fontWeight: "bold" }}>{viewer.hasJoined ? "Yes" : "No"}</TableCell>
                  <TableCell>{viewer.viewedTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SimpleShowLayout>
    </StyledShow>
  );
};
