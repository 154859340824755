import { useParams } from "react-router-dom";
import { useGetOne, useRedirect, Loading, Labeled } from "react-admin";
import {
  Avatar,
  Card,
  Divider,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  ListItemAvatar,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import { StyledShow } from "../../themes/ocoach-default/StyledComponents";
import { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const CustomLabel = ({ label }: any) => (
  <Typography variant="body2" sx={{ fontWeight: "bold" }}>
    {label}
  </Typography>
);

export const GlobalChallengeShow: React.FC = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne(
    "globalChallenge",
    { id },

    { onError: () => redirect("/error") }
  );
  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledShow title={`Global Challenges ${data.name ? `\\ ${data.name}` : "\\ Global Challenge"}`}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <Labeled label={<CustomLabel label="Challenge Id" />}>
          <TextField source="id" />
        </Labeled>
        <Labeled label={<CustomLabel label="Community Id" />}>
          <TextField source="communityId" label="Community Id" />
        </Labeled>
        <Labeled label={<CustomLabel label="Creator Id" />}>
          <TextField source="creatorId" label="Creator Id" />
        </Labeled>
        <Labeled label={<CustomLabel label="Creator Name" />}>
          <TextField source="creatorName" label="Creator Name" />
        </Labeled>
        <Labeled label={<CustomLabel label="Title" />}>
          <TextField source="name" label="Title" />
        </Labeled>
        <Labeled label={<CustomLabel label="Description" />}>
          <TextField source="description" label="Description" />
        </Labeled>
        <Labeled label={<CustomLabel label="Company Address" />}>
          <TextField source="companyAddress" label="Company Address" />
        </Labeled>
        <Labeled label={<CustomLabel label="Days Limit" />}>
          <TextField source="daysLimit" label="Days Limit" />
        </Labeled>
        <Labeled label={<CustomLabel label="Challenge Status" />}>
          <TextField source="challengeStatus" label="Challenge Status" />
        </Labeled>
        <Labeled label={<CustomLabel label="Challenge Type" />}>
          <TextField source="challengeType" label="Challenge Type" />
        </Labeled>
        <Labeled label={<CustomLabel label="Is Shareable" />}>
          <TextField source="isShareable" label="Is Shareable" />
        </Labeled>
        <Labeled label={<CustomLabel label="Share Link" />}>
          <TextField source="shareLink" label="Share Link" />
        </Labeled>
        <Labeled label={<CustomLabel label="Is Deleted" />}>
          <TextField source="isDeleted" label="Is Deleted" />
        </Labeled>
        <Labeled label={<CustomLabel label="Start DateTime" />}>
          <TextField source="startDateTime" label="Start DateTime" />
        </Labeled>
        <Labeled label={<CustomLabel label="End DateTime" />}>
          <TextField source="endDateTime" label="End DateTime" />
        </Labeled>
        <Labeled label={<CustomLabel label="Created DateTime" />}>
          <TextField source="createdDateTime" label="Created DateTime" />
        </Labeled>

        <Typography variant="h6" gutterBottom>
          Workouts
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Workout ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Workout Title</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Target Counts</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Workout Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.workouts?.map((workout: any) => (
                <TableRow key={workout.workoutId}>
                  <TableCell>{workout.workoutId}</TableCell>
                  <TableCell>{workout.workoutTitle}</TableCell>
                  <TableCell>{workout.targetCounts}</TableCell>
                  <TableCell>{(workout.workoutTime / 60).toFixed(1)} mins</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
          <Typography variant="h6" style={{ margin: "1rem" }}>
            Members
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Member ID</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Member Name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Photo</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Joined Date</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Progress</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Joinees</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.members?.map((member: any, index: number) => (
                <MemberRow key={index} member={member} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant="h6" gutterBottom>
          Share Links
        </Typography>
        {data?.shareLinks?.length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Member ID</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Member Name</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Photo</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Share Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.shareLinks?.map((sharelink: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{sharelink.id}</TableCell>
                    <TableCell>{sharelink.name}</TableCell>
                    <TableCell>
                      <Avatar src={sharelink.photo} alt={sharelink.name} />
                    </TableCell>
                    <TableCell>{sharelink.link}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="textSecondary">
            No Share Links available.
          </Typography>
        )}
      </SimpleShowLayout>
    </StyledShow>
  );
};

const MemberRow: React.FC<{ member: any }> = ({ member }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <TableRow>
      <TableCell>{member.memberId}</TableCell>
      <TableCell>{member.memberName}</TableCell>
      <TableCell>
        <Avatar src={member.memberPhoto} alt={member.memberName} />
      </TableCell>
      <TableCell>{new Date(new Date(0).setUTCFullYear(1) + member.joinedDateTime.date / 10000).toLocaleDateString()}</TableCell>
      <TableCell>{(member.progress.length > 0 ) ? member.progress[member.progress.length - 1].value : 0}%</TableCell>
      <TableCell>
        <IconButton
          aria-label="joinees"
          aria-controls={open ? "joinees-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          {member.sharedLinkJoinees?.length} <ArrowDropDownIcon />
        </IconButton>
        <Menu
          id="joinees-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "joinees-button",
          }}
        >
          {member.sharedLinkJoinees?.length > 0 ? (
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>ID</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Photo</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Joined Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {member.sharedLinkJoinees?.map((joinee: any, joineeIndex: number) => (
                    <TableRow key={joineeIndex}>
                      <TableCell>{joinee.memberId}</TableCell>
                      <TableCell>{joinee.memberName}</TableCell>
                      <TableCell>
                        <Avatar src={joinee.memberPhoto} alt={joinee.memberName} />
                      </TableCell>
                      <TableCell>
                        {joinee.joinedDateTime && new Date(new Date(0).setUTCFullYear(1) + joinee.joinedDateTime.date / 10000).toLocaleDateString()}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <MenuItem disabled>No Joinees</MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};
