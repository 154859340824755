import {List, Datagrid, TextInput } from 'react-admin';
import {TextField } from 'react-admin';

const challengeInvitationFilters = [
    <TextInput label="Challenge Name" source="challengeName" defaultValue=""/>,
];

export const ChallengeInvitationList = () => (
    <List filters={challengeInvitationFilters}>
        <Datagrid rowClick="show">
            <TextField source="id"/>
            <TextField label="Challenge Name" source="challengeName"/>
            <TextField label="Viewers Count" source="viewersCount"/>
            <TextField label="IOS Viewers Count" source="iosViewersCount"/>
            <TextField label="Android Viewers Count" source="androidViewersCount"/>
        </Datagrid>
    </List>
);

