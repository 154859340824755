import { useParams } from "react-router-dom";
import { useGetOne, useRedirect, Loading } from "react-admin";
import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { SimpleShowLayout, TextField } from "react-admin";
import { StyledShow } from "../../themes/ocoach-default/StyledComponents";

export const SurveyShow: React.FC = () => {
  const { id } = useParams();
  const redirect = useRedirect();
  const { data, isLoading } = useGetOne("survey", { id }, { onError: () => redirect("/error") });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <StyledShow title={`Survey ${data.memberName ? `\\ ${data.memberName}` : "\\ Survey"}`}>
      <SimpleShowLayout divider={<Divider flexItem />}>
      <p>Member Id: <br/> {data.memberId || "Not available"}</p>
      <p>Member Name: <br/> {data.memberName || "Not available"}</p>

        <TextField source="guestId" label="Guest Id" />
        <TextField source="isSkipped" label="Survey Skipped" style={{ fontWeight: "bold" }} />

        <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
          <Typography variant="h6" style={{ margin: "1rem" }}>
            Survey Data
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Question</TableCell>
                <TableCell>Answer(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.surveyData?.map((survey: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{survey.question}</TableCell>
                  <TableCell>
                    {survey.answer.length > 1 ? (
                      <ul style={{ paddingLeft: "1rem", margin: 0 }}>
                        {survey.answer.map((ans: string, ansIndex: number) => (
                          <li key={ansIndex}>{ans}</li>
                        ))}
                      </ul>
                    ) : (
                      survey.answer[0] || "Not Answered"
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SimpleShowLayout>
    </StyledShow>
  );
};
