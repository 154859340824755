import { List, Datagrid, TextField } from "react-admin";

export const SurveyList = () => {
  return (
    <List>
      <Datagrid
        rowClick={(id, resource, record) => {
          if (record.id) {
            return `/survey/${record.id}/show`;
          } else if (record.guestId) {
            return `/survey/${record.guestId}/show`;
          }
          return false;
        }}
      >
        <TextField label="Id" source="id" />
        <TextField label="Member Id" source="memberId" />
        <TextField label="Guest Id" source="guestId" />
        <TextField label="Member Name" source="memberName" />
        <TextField label="Answer Submitted" source="answeredCount" />
        <TextField label="Survey Skipped" source="isSkipped" />
      </Datagrid>
    </List>
  );
};
